import { useEffect, useState } from 'react';

import { ContentPageProvider, useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useUser } from '@netfront/gelada-identity-library';
import { Icons } from '@netfront/ui-library';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';

import '@egjs/flicking-plugins/dist/arrow.min.css';
import '@egjs/flicking-plugins/dist/pagination.min.css';
import '@egjs/react-flicking/dist/flicking.css';
import 'react-circular-progressbar/dist/styles.css';
import '@netfront/ui-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/css/global.css';
import '@netfront/ui-library/dist/css/helpers.css';

import '../styles/cms.css';
import '../styles/globals.css';
import '../styles/theme.css';
import '../styles/utils.css';
import { DummyPageView } from 'components/DummyPageView';

function App({ Component, pageProps }: AppProps) {
  const googleAnalyticsMeasurementId = String(process.env.REACT_APP_GOOGLE_ANALYTICS);
  const isGoogleAnalyticsDebugMode = String(process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE).toUpperCase() === 'Y';

  const { trackPageView, canRunAuthenticated } = useGoogleAnalytics();
  const { events } = useRouter();
  const { getUser } = useUser();

  const user = getUser();

  const [gtagConfigParams, setGtagConfigParams] = useState<ReturnType<typeof omitBy>>();

  useEffect(() => {
    if (canRunAuthenticated || !user) {
      trackPageView(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canRunAuthenticated]);

  useEffect(() => {
    DummyPageView();
    const handleRouteChange = () => {
      if (canRunAuthenticated || !user) {
        trackPageView(window.location.pathname);
      }
    };

    events.on('routeChangeComplete', handleRouteChange);
  }, []);

  useEffect(() => {
    if (gtagConfigParams || !user) {
      return;
    }

    setGtagConfigParams(() =>
      omitBy(
        {
          debug_mode: isGoogleAnalyticsDebugMode ? true : undefined,
          send_page_view: false,
          user_id: String(user.id),
        },
        isNil,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtagConfigParams, user]);

  return (
    <div id="root">
      {gtagConfigParams && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            gtag('config', '${googleAnalyticsMeasurementId}'${
          Object.keys(gtagConfigParams).length ? `, ${JSON.stringify(gtagConfigParams)}` : ''
        });
          `,
          }}
        />
      )}
      
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <Icons />
      <ContentPageProvider>
        {
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component {...pageProps} />
        }
      </ContentPageProvider>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
